.pagination {
    display: flex;
    list-style-type: none;
    padding: 0;
}

.pagination li {
    margin: 5px;
}

.pagination li a {
    border: 1px solid #ddd;
    padding: 5px 10px;
    text-decoration: none;
    color: #d9534f;
    cursor: pointer;
}

.pagination li a:hover {
    background-color: #ddd;
}

.pagination li.active a {
    color: white;
    background-color: #d9534f;
    border-color: #495057;
}
