.code-lang-single-form {
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f4f4f4;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.code-lang-title {
    margin-bottom: 20px;
    color: #333;
    text-transform: uppercase;
}

.placeholders-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.placeholder-row {
    display: flex;
    align-items: center;
    gap: 10px;
}

.placeholder-label {
    flex: 0 0 150px;
    font-weight: bold;
}

.text-input {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}
