.template-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.template-form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.template-form-group label {
    flex-basis: 140px;
    flex-shrink: 0;
    margin-right: 10px;
}

.template-form input,
.template-form select,
.template-form textarea {
    flex-grow: 1;
    padding: 10px;
    margin: 0 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.template-form .submit-button {
    width: 150px;
    align-self: center;
}

.template-form-title {
    text-align: center;
    margin-bottom: 20px;
}

#nav-button{
    display: flex;
}
