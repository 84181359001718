label{
    font-weight: bold;
}

.toggle-button {
    display: flex;
    align-items: center;
}

.toggle-button label {
    margin-right: 10px;
}

.toggle-on, .toggle-off {
    padding: 5px 10px;
    border: none;
    color: white;
    cursor: pointer;
}

.toggle-on {
    background-color: green;
}

.toggle-off {
    background-color: grey;
}
