.filter-bar {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.filter-bar input, .filter-bar select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
