.table-container {
    overflow-x: auto;
}

.data-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    margin-top: 20px;
}

.data-table th,
.data-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.data-table th {
    background-color: #f2f2f2;
}
