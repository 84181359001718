.campaign-detail {
    padding: 20px;
}

.campaign-detail h1 {
    margin-bottom: 20px;
}

.bounce-effect {
    animation: bounce 0.5s;
}


@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px);
    }
}
