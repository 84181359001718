
.container#no-access  {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f3f4f6;
    color: #333;
    text-align: center;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
}

#no-access h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #e74c3c;
}

#no-access p {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

#no-access .btn-back-home,
#no-access .btn-logout {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #3498db;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    cursor: pointer;
    margin: 5px;
}

#no-access .btn-back-home:hover,
#no-access .btn-logout:hover {
    background-color: #2980b9;
}

#no-access .btn-logout {
    background-color: #e74c3c;
}

#no-access .btn-logout:hover {
    background-color: #c0392b;
}
