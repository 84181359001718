.eligible-field {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.form-group label {
    flex-basis: 140px;
    flex-shrink: 0;
    margin-right: 10px;
}

.eligible-field input,
.eligible-field select {
    flex-grow: 1;
    padding: 10px;
    margin: 0 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}



