.layout {
    display: flex;
    min-height: 100vh;
    height: 100%;
}

h1{
    font-size: 1.7em;
}

.sidebar {
    width: 250px;
    background-color: #343a40;
    color: white;
    padding: 10px;
}

.sidebar a {
    text-decoration: none;
    color: white;
    display: block;
    padding: 10px;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.sidebar a:hover {
    background-color: #495057;
}

.content {
    flex-grow: 1;
    background-color: #f8f9fa;
    position: relative;
}

.main{
    padding: 20px
}

