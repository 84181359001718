.template-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.template-item {
    width: 400px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.template-preview {
    width: 100%;
    height: 300px;
    border: none;
}
