.dashboard-emailing .stats-globales, .campaign {
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
}


.dashboard-emailing .criteres {
    margin-top: 10px;
}

.dashboard-emailing .criteres > div{
    margin-top: 20px;
}

.dashboard-emailing .critere-table {
    display: table;
    width: 100%;
    border-collapse: collapse;
}

.dashboard-emailing .critere-header {
    background-color: #333;
    color: white;
    font-weight: bold;
}

.dashboard-emailing .critere-row {
    display: table-row;
}

.dashboard-emailing .critere-row div {
    display: table-cell;
    padding: 5px 10px;
    border: 1px solid #ddd;
}

.dashboard-emailing .campaign h3{
    cursor: pointer;
}