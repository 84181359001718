.button-sticky-top{
    position: sticky;
    top: 15px;
}
.button-fixed-bottom {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    width: 200px;
    z-index: 1000;
}